<template>
   <v-container fluid>
       <v-row justify="center">
           <v-col></v-col>
           <v-col cols="12" class="text-center">
               <v-icon size="90">mdi-washing-machine</v-icon>
               <h2>¿Cómo lava su ropa?</h2>
           </v-col>
           <v-col cols="12" sm="12" md="4" class="pb-0 text-center" justify="center">
               <v-chip-group
                    v-model="lavadora"
                    column
                    :error-messages="lavadoraErrors"
                    class="text-center pb-2"
                    @change="setLavadora($event)"
                >
                    <v-chip
                    filter
                    outlined
                    value="antigua"
                    >
                        Lavadora antigua
                    </v-chip>

                    <v-chip
                    filter
                    outlined
                    value="eficiente"
                    >
                        Lavadora eficiente
                    </v-chip>

                    <v-chip
                    filter
                    outlined
                    value="mano"
                    >
                        A mano
                    </v-chip>
               </v-chip-group>

                <h2 v-if="lavadora">¿Cuantas veces lava a la semana?</h2>

               <v-text-field
                    v-if="lavadora"
                    v-model="lavadoraTime"
                    :error-messages="lavadoraTimeErrors"
                    label="Lavado por semana"
                    outlined
                    @input="setLavadoraTime($event)"
                    @blur="$v.lavadoraTime.$touch()"
                    inputmode="numeric"
                ></v-text-field>

           </v-col>
           <v-col cols="12" class="text-center">
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    to="/q15"
                >
                    <v-icon left>mdi-chevron-left</v-icon> Regresar
                </v-btn>
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    to="/q17"
                    :disabled="!lavadora || !lavadoraTime"
                    >
                    Continuar <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
           </v-col>
           <v-col cols="12" sm="8" class="text-justify">
                <v-alert
                    outlined
                    color="info"
                    >
                    <div class="title">
                        Consejos: Utilice la lavadora sólo cuando esté llena.
                    </div>
                    <div>
                        ¿En busca de una nueva lavadora? Elija un modelo ENERGY STAR ENERGY STAR de agua y energía-eficiente. Ahorrará galones de agua en cada carga (y también ahorrará energía).
                        <br>
                        <br>
                        Lave menos sus pantalones de mezclilla o jeans – lavarlos mucho hará que se desgasten más rápidamente. Considere secarlos al aire libre o incluso orearlos en la parrilla trasera del congelador.
                        <br>
                        <br>
                        Seque su ropa en un tendedero. Considere que si ahorra energía, también estará ahorrando agua, porque producir electricidad convencional requiere una enorme cantidad de agua.
                    </div>
                </v-alert>
           </v-col>
       </v-row>
   </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
//eslint-disable-next-line
import { required, numeric } from 'vuelidate/lib/validators'

export default {
    
    mixins: [validationMixin],

    validations: {
      lavadora: { required, numeric },
      lavadoraTime: { required, numeric },
    },

    data() {
        return {
            lavadora: '',
            lavadoraTime: ''
        }
    },

    mounted() {
        this.lavadora = this.$store.state.lavadora
        this.lavadoraTime = this.$store.state.lavadoraTime
        this.$store.state.progress = 90
        this.goBackIfEmpty()
    },

    computed: {
        lavadoraErrors () {
            const errors = []
            if (!this.$v.lavadora.$dirty) return errors
            !this.$v.lavadora.required && errors.push('Preferencias de lavadora es requerido.')
            !this.$v.lavadora.numeric && errors.push('Debe ser un número.')
            return errors
        },
        lavadoraTimeErrors () {
            const errors = []
            if (!this.$v.lavadoraTime.$dirty) return errors
            !this.$v.lavadoraTime.required && errors.push('Lavado por semana es requerido.')
            !this.$v.lavadoraTime.numeric && errors.push('Debe ser un número.')
            return errors
        },
    },

    methods: {
        setLavadora(value) {
            this.$store.state.lavadora = value
            this.$v.lavadora.$touch()
        },
        setLavadoraTime(value) {
            this.$store.state.lavadoraTime = value
            this.$v.lavadoraTime.$touch()
        },
       goBackIfEmpty(){
           if(!this.$store.state.municipio) {
                this.$router.push('/municipio')
           }else if(!this.$store.state.personas) {
                this.$router.push('/personas')
           }else if(!this.$store.state.cereales) {
                this.$router.push('/q1')
           }else if(!this.$store.state.carnes) {
               this.$router.push('/q2')
           }else if(!this.$store.state.lacteos) {
               this.$router.push('/q3')
           }else if(!this.$store.state.huevos) {
               this.$router.push('/q4')
           }else if(!this.$store.state.comida) {
               this.$router.push('/q5')
           }else if(!this.$store.state.dulces) {
               this.$router.push('/q6')
           }else if(!this.$store.state.verduras) {
               this.$router.push('/q7')
           }else if(!this.$store.state.frutas) {
               this.$router.push('/q8')
           }else if(!this.$store.state.tuberculos) {
               this.$router.push('/q9')
           }else if(!this.$store.state.cafe) {
               this.$router.push('/q10')
           }else if(!this.$store.state.tee) {
               this.$router.push('/q11')
           }else if(!this.$store.state.ducha) {
               this.$router.push('/q12')
           }else if(!this.$store.state.manos) {
               this.$router.push('/q13')
           }else if(!this.$store.state.inodoro) {
               this.$router.push('/q14')
           }else if(!this.$store.state.fregadero) {
               this.$router.push('/q15')
           }
       }
    },
}
</script>

<style>
    .v-slide-group__content{
        justify-content: center;
    }
</style>